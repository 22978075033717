import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ModalManager } from '../shared/modals/modal.manager.service';
import { NotificationClosedEvent } from '../shared/modals/notification/notification.component';
import { CustomerInfo, DonationAmount, Charge } from '../shared/models/payment';
import { PaymentService } from '../shared/payment.service';
import { NgForm } from '@angular/forms';


declare var stripe: any;
declare var elements: any;


@Component({
  selector: 'uwl-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('cardInfo') cardInfo: ElementRef;

  public customerInfo: CustomerInfo;
  public five: DonationAmount;
  public ten: DonationAmount;
  public twenty: DonationAmount;
  public fifty: DonationAmount;
  public hundred: DonationAmount;
  public amountEntered: number;
  private donationAmounts: Array<DonationAmount>;
  public donationTotal: number;
  public isDonationProcessing: boolean;
  public paymentForm: NgForm;


  public card: any;
  public cardHandler = this.onChange.bind(this);
  public error: string;

  constructor(private modalManager: ModalManager, private paymentService: PaymentService, private cd: ChangeDetectorRef) { }

  ngOnInit() {

    this.customerInfo = new CustomerInfo();

    this.donationTotal = 0;
    this.amountEntered = 0;
    this.isDonationProcessing = false;

    this.donationAmounts = new Array<DonationAmount>();

    this.five = new DonationAmount(5, false);
    this.ten = new DonationAmount(10, false);
    this.twenty = new DonationAmount(20, false);
    this.fifty = new DonationAmount(50, false);
    this.hundred = new DonationAmount(100, false);

    this.donationAmounts.push(this.five);
    this.donationAmounts.push(this.ten);
    this.donationAmounts.push(this.twenty);
    this.donationAmounts.push(this.fifty);
    this.donationAmounts.push(this.hundred);

  }

  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  donate(form: NgForm): void {
    this.paymentForm = form;

    this.donationTotal = 0;

      // calculate donation totals
    this.donationAmounts.forEach((donation: DonationAmount) => {
          if (donation.checked === true) {
              this.donationTotal += donation.amount;
          }
      });

    this.donationTotal += this.amountEntered;

    let options = {};

    if (isNaN(this.donationTotal) || this.donationTotal === 0) {
      options = { title: 'Donation Confirmation', message: 'You must select an amount to donate',
      cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
  } else {
      options = {
          title: 'Donation Confirmation',
          message: 'Are you sure you want to donate ' + '$' + this.donationTotal.toString(),
          okText: 'Yes',
          cancelText: 'No',
          details: { customer: this.customerInfo, total: this.donationTotal },
          showDenyBtn: true,
          showConfirmBtn: true

,      };
  }


    const modal = this.modalManager.confirm(options);
    modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => this.onNotificationClosed(e), (response) => {
      // this.logger.error('CategoriesComponent:remove(): Error removing category.');
    });

  }

  private onNotificationClosed(e: NotificationClosedEvent) {
    console.log('notification', e);
    this.processStripeToken(e.payload.customer, e.payload.total);
  }

  private async processStripeToken(customerInfo: CustomerInfo, total: number) {

    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
      const options = { title: 'Donation Confirmation', message: 'There was a problem processing your donation. Please try again later',
      cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
      const modal = this.modalManager.confirm(options);
      modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => {}, (response) => {
        // this.logger.error('CategoriesComponent:remove(): Error removing category.');
      });
    } else {
      console.log('Success!', token);
      this.processPayment(customerInfo, total, token);
    }

  }

  private processPayment(customerInfo: CustomerInfo, total: number, token: any): void {

    const charge = new Charge();
    charge.amount = total;
    charge.currency = 'usd';
    charge.description = 'New Donation';
    charge.token = token.id;



    this.paymentService.createPayment(charge).subscribe((response) => {
      this.card.clear();
      this.customerInfo.clear();
      this.paymentForm.resetForm();
      const options = { title: 'Donation Confirmation', message: 'Thank you for your donation.',
      cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
      const modal = this.modalManager.confirm(options);
      modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => {}, (response) => {
        // this.logger.error('CategoriesComponent:remove(): Error removing category.');
      });
    }, (response) => {
      this.card.clear();
      this.customerInfo.clear();
      this.paymentForm.resetForm();
      const options = { title: 'Donation Confirmation', message: 'There was a problem processing your donation. Please try again later',
      cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
      const modal = this.modalManager.confirm(options);
      modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => {}, (response) => {
        // this.logger.error('CategoriesComponent:remove(): Error removing category.');
      });
    });

    // this.paymentService.createPayment(charge).then((response) => {
    //     this.$log.log('Donation Created');
    //     this.isDonationProcessing = false;
    //     this.showDonateModal(Messages.DONATION_SUCCESS_TITLE, Messages.DONATION_SUCCESS_MESSAGE);
    // }).catch((response) => {
    //     this.$log.log('Donation Failed');
    //     this.isDonationProcessing = false;
    //     this.showDonateModal(Messages.DONATION_FAIL_TITLE, Messages.DONATION_FAIL_MESSAGE);

    //     });
  }

}
