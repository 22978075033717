import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FormsModule } from '@angular/forms';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { ModalModule  } from 'ngx-bootstrap';


import { InMemoryCacheService } from './shared/in-memory-cache.service';
import { HttpCoreService } from './shared/core.service';
import { InterceptorService } from './shared/interceptor.service';
import { ModalManager } from './shared/modals/modal.manager.service';
import { PaymentService } from './shared/payment.service';
import { ContactService } from './shared/contact.service';


import { PostsResolver } from './shared/post-resolver';
import { MediaResolver } from './media/media.resolver';

import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './home/features/features.component';
import { PageComponent } from './page/page.component';
import { MediaComponent } from './media/media.component';
import { GalleryComponent } from './media/gallery/gallery.component';
import { DonateComponent } from './donate/donate.component';
import { ContactComponent } from './contact/contact.component';
import { NotificationComponent } from './shared/modals/notification/notification.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    FeaturesComponent,
    PageComponent,
    MediaComponent,
    GalleryComponent,
    DonateComponent,
    ContactComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxAudioPlayerModule,
    BrowserAnimationsModule,
    FormsModule,
    CreditCardDirectivesModule,
    ModalModule.forRoot(),
    NgxSpinnerModule
  ],
  entryComponents: [NotificationComponent],
  providers: [InMemoryCacheService, HttpCoreService,
    PostsResolver, MediaResolver, ModalManager, ContactService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
  }, PaymentService],
  bootstrap: [AppComponent]
})
export class AppModule { }
