import { Injectable } from '@angular/core';
import { Observable as RxObservable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Charge } from './models/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  public createPayment(charge: Charge): RxObservable<{}> {
    return this.http.post(environment.baseUrl + environment.chargePayment, charge);

  }

}
