import { Injectable } from '@angular/core';
import { Category, Post, Media } from './models/cms';

@Injectable({
  providedIn: 'root'
})
export class InMemoryCacheService {

  private _categories: Array<Category>;
  private _posts: Array<Post>;
  private _media: Array<Media>;

  constructor() { }

  get categories(): Category[] {
    return this._categories;
  }

  set categories(value: Category[]) {
    this._categories = value;
  }

  get posts(): Post[] {
    return this._posts;
  }

  set posts(value: Post[]) {
    this._posts = value;
  }

  get media(): Media[] {
    return this._media;
  }

  set media(value: Media[]) {
    this._media = value;
  }
}
