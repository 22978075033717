import { Injectable } from '@angular/core';
import { NotificationComponent } from '../../shared/modals/notification/notification.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable()
export class ModalManager {

  constructor(private modalService: BsModalService) { }

  public confirm(options: any): any {

    const modalRef = this.modalService.show(NotificationComponent);

    modalRef.content.title = options.title;
    modalRef.content.showConfirmBtn = !options.showConfirmBtn ? false : true;
    modalRef.content.showDenyBtn = !options.showDenyBtn ? false : true;
    modalRef.content.denyBtnLabel = options.cancelText ? options.cancelText : 'Cancel';
    modalRef.content.confirmBtnLabel = options.okText ? options.okText : 'Save';
    modalRef.content.message = options.message;
    modalRef.content.selectedItem = options.details ? options.details : {};

    return modalRef.content;
  }

}
