import { Component, OnInit } from '@angular/core';
import { InMemoryCacheService } from './shared/in-memory-cache.service';
import { HttpCoreService } from './shared/core.service';
import { Category, Post } from './shared/models/cms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'uml-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'upwithlife';
  public pageTitle: string;
  private alerts: Array<any>;
  public navOpen: boolean;
  public audio: any;

  constructor(private cacheService: InMemoryCacheService, private coreService: HttpCoreService<Category | Post>) {}

  ngOnInit() {
    this.pageTitle = 'Home';
    this.navOpen = false;
    this.alerts = [];
  }

  trackAlertFunc(index) {
    return index;
  }

}
