import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PostsResolver } from './shared/post-resolver';
import { PageComponent } from './page/page.component';
import { MediaComponent } from './media/media.component';
import { MediaResolver } from './media/media.resolver';
import { ContactComponent } from './contact/contact.component';
import { DonateComponent } from './donate/donate.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, resolve: {posts: PostsResolver} },
  { path: 'page', component: PageComponent, resolve: {posts: PostsResolver} },
  { path: 'media', component: MediaComponent, resolve: {media: MediaResolver} },
  { path: 'contact', component: ContactComponent },
  { path: 'donate', component: DonateComponent },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
