import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Media } from '../shared/models/cms';
import { HttpCoreService } from '../shared/core.service';
import { environment } from 'src/environments/environment';
import { InMemoryCacheService } from '../shared/in-memory-cache.service';

@Injectable()
export class MediaResolver implements Resolve<{}> {

    constructor(private coreService: HttpCoreService<Media>, private cacheService: InMemoryCacheService) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        if (this.cacheService.media) {
            return this.cacheService.media;
        } else {
            return this.coreService.getAll(environment.allMedia);
        }
    }

}

