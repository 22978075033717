import { Injectable } from '@angular/core';
import { Observable as RxObservable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Charge } from './models/payment';
import { Contact } from './models/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  public submitContact(contact: Contact): RxObservable<{}> {
    return this.http.post(environment.baseUrl + environment.contact, contact);

  }

}
