import { Injectable } from '@angular/core';
import { Observable as RxObservable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpCoreService<T> implements IHttpCoreService<T> {

  constructor(private http: HttpClient) { }

  getAll(endPoint: string) {
    return this.http.get<T[]>(environment.baseUrl + endPoint, {observe: 'body'});
  }

  getByFilters(endPoint: string, filters: any) {
    return this.http.post<T[]>(environment.baseUrl + endPoint, filters, {observe: 'body'});
  }

  getById(endPoint: string, id: number|string): RxObservable<T> {
    return this.http.get<T>(environment.baseUrl + `${endPoint}${id}`, {observe: 'body'});
  }

  create(endPoint: string, post: T): RxObservable<T> {
    return this.http.post<T>(environment.baseUrl + endPoint, post, {observe: 'body'});
  }

  update(endPoint: string, post: T): RxObservable<T> {
    return this.http.put<T>(environment.baseUrl + endPoint + post['id'], post, {observe: 'body'});
  }

  delete(endPoint: string, item: T): RxObservable<T> {
    return this.http.request<T>('Delete', environment.baseUrl + endPoint + item['id'], {body: item});
  }

}


export interface IHttpCoreService<T> {
  getAll(endPoint: string);
  getByFilters(endPoint: string, filters: any);
  getById(endPoint: string, id: number): RxObservable<T>;
  create(endPoint: string, post: T): RxObservable<T>;
  update(endPoint: string, post: T): RxObservable<T>;
  delete(endPoint: string, item: T): RxObservable<T>;
}
