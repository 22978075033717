import { Component, OnInit, Input } from '@angular/core';
import { Media } from 'src/app/shared/models/cms';


declare var $: any;

@Component({
  selector: 'uwl-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  @Input() media: Media[];

  constructor() { }

  ngOnInit() {


  }

  showGallery() {

    // $('#lightgallery').lightGallery({
    //   share: true
    // });
  }

}
