import { Component, OnInit } from '@angular/core';
import { InMemoryCacheService } from '../shared/in-memory-cache.service';
import { Post, Media } from '../shared/models/cms';
import { ActivatedRoute } from '@angular/router';
import { HttpCoreService } from '../shared/core.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'uwl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public howItWorks: Post;
  public expectedOutcomes: Post;
  public ministryProgramFormat: Post;
  public ministryTechniqueApproach: Post;
  public ministryGoalsObjectives: Post;
  public ourMission: Post;
  public posts: Array<Post>;

  constructor(private cacheService: InMemoryCacheService, private route: ActivatedRoute, private coreService: HttpCoreService<Media>) { }

  ngOnInit() {
    this.posts = this.route.snapshot.data['posts'];
    this.cacheService.posts = this.posts;
    this.populateFeatures(this.posts);

  }

  private populateFeatures(posts: Post[]) {
    this.howItWorks = posts.find((p) => p.slug === 'how-it-works');
    this.expectedOutcomes = posts.find((p) => p.slug === 'expected-outcomes');
    this.ministryProgramFormat = posts.find((p) => p.slug === 'ministry-program-format');
    this.ministryTechniqueApproach = posts.find((p) => p.slug === 'ministry-technique-approach');
    this.ministryGoalsObjectives = posts.find((p) => p.slug === 'ministry-goals-and-objectives');
    this.ourMission = posts.find((p) => p.slug === 'our-mission');

    this.getPostMedia(this.ministryProgramFormat);
    this.getPostMedia(this.ministryTechniqueApproach);
    this.getPostMedia(this.ministryGoalsObjectives);
  }

  private getPostMedia(post: Post) {
    if (!post.featured_media_details) {
      this.coreService.getById(environment.media, post.featured_media)
      .subscribe((m) => {
        post.featured_media_details = m.media_details;
      });
    }
  }

}
