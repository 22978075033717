export class DonationAmount {

    public amount: number;
    public checked: boolean;

    constructor(amount: number, checked: boolean) {

        this.amount = amount;
        this.checked = checked;

    }
}

export class Charge {

    public amount: number;
    public currency: string;
    public description: string;
    public customerId: string;
    public token: string;
    public applicationFee: number;
    public capture: boolean;

    constructor() {

    }
}

export class CustomerInfo {
    public name: string;
    public email: string;

    clear() {
        this.name = '';
        this.email = '';
    }
}
