import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactService } from '../shared/contact.service';
import { Contact } from '../shared/models/contact';
import { ModalManager } from '../shared/modals/modal.manager.service';
import { NotificationClosedEvent } from '../shared/modals/notification/notification.component';

@Component({
  selector: 'uwl-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactInfo: Contact;
  constructor(private contactService: ContactService, private modalManager: ModalManager) { }

  ngOnInit() {
      this.contactInfo = new Contact();
  }

  onSubmit(form: NgForm) {
    this.contactInfo.save = false;
    this.contactService.submitContact(this.contactInfo).subscribe((response) => {
       this.contactInfo.clear();
       form.resetForm();
       const options = { title: 'Contact Confirmation', message: 'Thank you for submitting your information.',
       cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
       const modal = this.modalManager.confirm(options);
       modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => {}, (response) => {
        // this.logger.error('CategoriesComponent:remove(): Error removing category.');
       });
    }, (response) => {
      this.contactInfo.clear();
      form.resetForm();
      const options = { title: 'Contact Confirmation',
      message: 'There was a problem sending your contact information. Please try again later.',
      cancelText: 'OK', showDenyBtn: true, showConfirmBtn: false };
      const modal = this.modalManager.confirm(options);
      modal.modalClosedEvent.subscribe((e: NotificationClosedEvent) => {}, (response) => {
        // this.logger.error('CategoriesComponent:remove(): Error removing category.');
      });
    });
  }

}
