import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Post, Title, Content } from './models/cms';
import { HttpCoreService } from './core.service';
import { environment } from 'src/environments/environment';
import { InMemoryCacheService } from './in-memory-cache.service';

@Injectable()
export class PostsResolver implements Resolve<{}> {

    constructor(private postsService: HttpCoreService<Post>, private cacheService: InMemoryCacheService) {

    }

    resolve(route: ActivatedRouteSnapshot) {
        if (this.cacheService.posts) {
            return this.cacheService.posts;
        } else {
            return this.postsService.getAll(environment.post);
        }
    }

}

