import { Guid, Title, Content, Excerpt } from './common';
import { Sizes, ImageMeta } from '../../models/cms/media';

export class Post {
    id: number;
    date: Date;
    date_gmt: Date;
    guid: Guid;
    modified: Date;
    modified_gmt: Date;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Title | string;
    content: Content;
    excerpt: Excerpt;
    author: number;
    featured_media: number;
    comment_status: string;
    ping_status: string;
    sticky: boolean;
    template: string;
    format: string;
    meta: any[];
    categories: any[];
    tags: any[];
    featured_media_details: FeaturedMediaDetails;
}

export class FeaturedMediaDetails {
    width: number;
    height: number;
    file: string;
    sizes: Sizes;
    image_meta: ImageMeta;
}


