export class Contact {
    public name: string;
    public email: string;
    public message: string;
    public save: boolean;

    clear() {
        this.name = '';
        this.email = '';
        this.message = '';
        this.save = false;
    }
}
