import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { InMemoryCacheService } from '../shared/in-memory-cache.service';
import { ActivatedRoute } from '@angular/router';
import { Media } from '../shared/models/cms';
import { GalleryComponent } from './gallery/gallery.component';

declare var $: any;


@Component({
  selector: 'uwl-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  public media: Array<Media>;
  public activeItem: string;
  public selectedVideo: any;
  public showGallery: boolean;

  @ViewChild(GalleryComponent) gallery: GalleryComponent;

  constructor(private cacheService: InMemoryCacheService, private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.media = this.route.snapshot.data['media'];
    this.cacheService.media = this.media;
    this.activeItem = 'images';
    this.showGallery = false;

    setTimeout(() => {

    $('#lightgallery').lightGallery({
      share: false,
      download: false
    });
    }, 2000);

  }

  onActiveItemChange(activeItem: string): void {
    this.activeItem = activeItem;
  }

  openGallery(): void {
    console.log('showing gallery');
    // this.$rootScope.$broadcast("MediaController.VIEW_SELECTED_MEDIA", { items: this.photos });
    // this.$rootScope.$emit("MediaController.VIEW_SELECTED_MEDIA", { items: this.photos });
    this.showGallery = true;
    this.gallery.showGallery();

  }

  onVideoSelected(e: Event, idx: number, photo: any): void {

    if (photo.selected) {
      photo.selected = false;
    } else {
      photo.selected = true;
    }

    this.selectedVideo = photo;



  }


}
