import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Track, MatAdvancedAudioPlayerComponent } from 'ngx-audio-player';

@Component({
  selector: 'uwl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public displayTitle = true;
  public displayPlaylist = true;
  public tracks: Track[];

  @ViewChild('audioPlayer') audioPlayer: MatAdvancedAudioPlayerComponent;

  constructor() { }

  ngOnInit() {
    this.tracks = [
      {
        title: 'Up With Life',
        link: 'assets/music/upwithlife.mp3'
      },
      {
        title: 'I came to Jesus',
        link: 'assets/music/i-came-to-jesus.mp3'
      },
      {
        title: 'Nothing in this world',
        link: 'assets/music/nothing-in-this-world.mp3'
      },
      {
        title: 'Pray for me',
        link: 'assets/music/pray-for-me.mp3'
      },
      {
        title: 'Up With Life Instrumental',
        link: 'assets/music/instrumental-upwithlife.mp3'
      }
    ];

    setTimeout(() => {
      this.audioPlayer.play();
    }, 1000);
  }

}

