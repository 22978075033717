import { Component, OnInit, Input } from '@angular/core';
import { Post } from 'src/app/shared/models/cms/post';

@Component({
  selector: 'uwl-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

  @Input() howItWorks: Post;
  @Input() expectedOutcomes: Post;
  @Input() ourMission: Post;

  constructor() { }

  ngOnInit() {
  }

}
