import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InMemoryCacheService } from './in-memory-cache.service';
import { NotificationComponent } from './modals/notification/notification.component';
// import { ErrorService } from './error.service';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private memoryCache: InMemoryCacheService,  private modalService: BsModalService, private spinner: NgxSpinnerService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            // do stuff with response if you want
            this.spinner.hide();
        }
        return event;
    }),
    catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          // const modalRef = this.modalService.show(NotificationComponent);
          // modalRef.content.title = 'Server Error';
          // modalRef.content.denyBtnLabel = 'OK';
          // modalRef.content.message = 'Please contact system administrator.';
          console.log('Please contact system administrator.');
          this.spinner.hide();
        } else {
        //  this.errorService.handleError(error);
        this.spinner.hide();
        }

        return throwError(error);
    }));
  }
};
