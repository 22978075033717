import { Component, OnInit } from '@angular/core';
import { Post } from '../shared/models/cms';
import { InMemoryCacheService } from '../shared/in-memory-cache.service';
import { ActivatedRoute } from '@angular/router';
import { HttpCoreService } from '../shared/core.service';

@Component({
  selector: 'uwl-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  public posts: Array<Post>;
  public page: Post;

  constructor(private cacheService: InMemoryCacheService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.posts = this.route.snapshot.data['posts'];
    this.cacheService.posts = this.posts;
    this.route.queryParams.subscribe(qp => {
      this.page = this.posts.find((p) => p.slug === qp.name);
    });

  }

}
