import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'uwl-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() showConfirmBtn: boolean;
  @Input() showDenyBtn: boolean;
  @Input() confirmBtnLabel: string;
  @Input() denyBtnLabel: string;
  @Input() selectedItem: any;

  @Output() modalClosedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(public activeModal: BsModalRef) {}

  ngOnInit() {
    this.showConfirmBtn = false;
    this.showDenyBtn = true;
    this.confirmBtnLabel = '';
    this.denyBtnLabel = 'Ok';
  }

  public onConfirmBtnClicked(e: Event) {
    this.modalClosedEvent.emit(new NotificationClosedEvent('NotificationClosedEvent.CONFIRM', this.selectedItem));
    this.activeModal.hide();
  }

}

export class NotificationClosedEvent {

  private _type: string;
  private _payload: any;

  constructor(type: string, payload: any) {
    this._type = type;
    this._payload = payload;
  }

  get type(): string {
    return this._type;
  }

  get payload(): any {
    return this._payload;
  }
}
